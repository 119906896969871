import React from 'react'
import Helmet from 'react-helmet'
import { Link,graphql } from 'gatsby'
import uniq from 'lodash/uniq'
import throttle from 'lodash/throttle'
import debounce from 'lodash/debounce'
import * as _h from '../helpers'
import ArticleBox from '../components/ArticleBox'
import stemLogo from '../assets/stem_logo_black.svg'

// http://ganlanyuan.github.io/tiny-slider/
import "tiny-slider/src/tiny-slider.scss" //js loaded in componentDidMount()

//https://github.com/feimosi/baguetteBox.js
import baguetteBox from 'baguettebox.js'
import 'baguettebox.js/dist/baguetteBox.min.css'

import Layout from '../components/layout'
import styles from "./blogpost.module.scss"

class BlogPostTemplate extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      content2Visible: false,
      content3Visible: false,
      favourite: false,
    }

    this.images = []
  }

  createSliders = (event) => {

    let parentEl = event ? event.target : document
    let wrappers = parentEl.querySelectorAll('.imageWrapper')

    if (!wrappers.length || wrappers.length == 0) return false

    for (let i = 0; (typeof this.tns !== 'undefined' && i < wrappers.length); i++) {
      wrappers[i].classList.replace('imageWrapper', 'imageWrapperInitialized')
      let imagesInWrapper = wrappers[i].querySelectorAll('.imageContent').length

      wrappers[i].querySelectorAll('p').forEach(n => {
        if (n.parentNode == wrappers[i]) n.remove()
      })


      this.tns({
        container: wrappers[i],
        items: 1,
        edgePadding: null,
        autoHeight: true,
        loop: imagesInWrapper > 1,
        nav: false,
        gutter: null,
        controlsText: ["←", "→"],
        responsive: {
          719: {
            items: imagesInWrapper > 2 ? 2 : imagesInWrapper,
            loop: imagesInWrapper > 2,
            edgePadding: imagesInWrapper > 2 ? 64 : null,
            gutter: imagesInWrapper == 1 ? null : 16,
          },
          1023: {
            items: imagesInWrapper > 3 ? 3 : imagesInWrapper,
            loop: imagesInWrapper > 3,
            edgePadding: imagesInWrapper > 3 ? 64 : null,
            gutter: imagesInWrapper == 1 ? null : 16,
          },
        }
      })
    }

    if (this.gallery) {
      let className = this.gallery.className
      this.gallery = null
      this.baguetteBox = baguetteBox.run('.' + className)
    }
  }

  setContent2Visible = event => {
    this.setState({content2Visible: true})
  }

  setContent3Visible = event => {
    this.setState({content3Visible: true})
  }

  showImage = event => {
    if (event.target.classList.contains('tns-complete')) {
      let image = event.target
      let galleryIndex = this.images.indexOf(image.src)

      baguetteBox.show(galleryIndex, this.baguetteBox[0])
    }
  }

  trackHit = (type) => {
    if (!this.props.data.site.siteMetadata.productionMode) return false

    const datoCMSAPItoken = this.props.data.site.siteMetadata.fullAPIToken

    fetch(
      'https://graphql.datocms.com/',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${datoCMSAPItoken}`,
        },
        body: JSON.stringify({
          query: '{ statisztika(filter: {cikk: {eq: "' + this.props.data.datoCmsCikkek.id.split('-')[1] + '"}}){ id, latogatas, tetszik, nemTetszik, cikk { id } }}'
        }),
      }
    )
    .then((res) => res.json())
    .then((stat) => {
      let statData = stat.data.statisztika
      let saveUrl = stat.data.statisztika ? 'https://site-api.datocms.com/items/' + stat.data.statisztika.id : 'https://site-api.datocms.com/items'
      let newStat = !statData
      let needRefresh = false
      let newStatData = {
        type: 'item',
        attributes: {
          latogatas: newStat ? 0 : statData.latogatas || 0,
          nem_tetszik: newStat ? 0 : statData.nemTetszik || 0,
          tetszik: newStat ? 0 : statData.tetszik || 0,
        },
      }

      if (newStat) {
        newStatData.relationships = {
          item_type: {
            data: {
              type: 'item_type',
              id: '49088'
            }
          }
        }
        newStatData.attributes.cikk = this.props.data.datoCmsCikkek.id.split('-')[1]
      } else {
        newStatData.id = statData.id
      }

      switch (type) {
        case 'latogatas':
          let visitedPosts = localStorage.getItem('visitedPosts') || ''

          if (visitedPosts.indexOf(this.props.data.datoCmsCikkek.id.split('-')[1]) == -1) {
            visitedPosts += (this.props.data.datoCmsCikkek.id.split('-')[1] + '-')
            localStorage.setItem('visitedPosts', visitedPosts)
            newStatData.attributes.latogatas++
            needRefresh = true
          }


          break;
        default:
          // !!! votes not set up yet

          // let votedPosts = localStorage.getItem('votedPosts') || ''

          // if (votedPosts.indexOf(this.props.data.datoCmsCikkek.id.split('-')[1]) == -1) {
          //   votedPosts += (this.props.data.datoCmsCikkek.id.split('-')[1] + '-')
          //   localStorage.setItem('votedPosts', votedPosts)
          //   needRefresh = true
          //   newStatData.attributes[type]++
          // }


          break;
      }

      // save hits on server
      needRefresh && fetch(
        saveUrl,
        {
          method: newStat ? 'POST' : 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${datoCMSAPItoken}`,
          },
          body: JSON.stringify({
            "data": newStatData
          }),
        }
      )
    })
    .catch((error) => {
      console.log(error);
    });

  }

  saveFav = event => {
    let favPosts = localStorage.getItem('favPosts') || ''
    let postId = this.props.data.datoCmsCikkek.id.split('-')[1]

    if (favPosts.indexOf(postId) == -1) {
      favPosts += (postId + '-')
    } else {
      favPosts = favPosts.replace(postId + '-', '')
    }

    localStorage.setItem('favPosts', favPosts)
    this.updateFav()
  }

  updateFav () {
    let favPosts = localStorage.getItem('favPosts') || ''
    let postId = this.props.data.datoCmsCikkek.id.split('-')[1]

    this.setState({
      favourite: favPosts.indexOf(postId) > -1
    })
  }

  componentDidMount() {
    document.body.addEventListener('DOMSubtreeModified', debounce(this.createSliders, 200))

    if (!this.tns) {
      import('tiny-slider/src/tiny-slider.module.js')
      .then(({tns}) => {
        this.tns = tns
        this.createSliders()
      })
    } else {
      this.createSliders()
    }

    document.body.addEventListener('click', this.showImage)

    this.updateFav()
    this.trackHit('latogatas')
  }

  componentWillUnmount() {
    document.body.removeEventListener('DOMSubtreeModified', this.createSliders)
    document.body.removeEventListener('click', this.showImage)
  }

  componentDidUpdate() {
  }

  render() {
    const post = this.props.data.datoCmsCikkek
    const { defaultMetadata, previous, next } = this.props.pageContext
    const pageDescription = post.lead || defaultMetadata.fallbackSeo.description
    const pageTitle = post.cim + defaultMetadata.titleSuffix

    const prepareContent = (htmlString) => {
      if(typeof document !== "undefined") {
        const div = document.createElement('div')

        div.innerHTML = htmlString
                          .trim()
                          .replace("$$kiemelt$$", "<div class='highlightedContent'>")
                          .replace("$$/kiemelt$$", "</div>")
                          .replace("$$kep$$", "<div class='highlightedContentImage'><div>")
                          .replace("$$/kep$$", "</div></div>")
                          .replace("$$szoveg$$", "<div class='highlightedContentText'>")
                          .replace("$$/szoveg$$", "</div>")
                          .replace("<p></p>", "")

        Array.from(div.querySelectorAll('p:empty')).forEach(el=>{
          el.remove();
        })

        // prepare images
        const images = div.querySelectorAll('img')

        const wrap = function(el, wrapper, wrapperClass) {
          el.parentNode.insertBefore(wrapper, el)
          wrapper.classList.add(wrapperClass)
          wrapper.appendChild(el)
        }

        for (let i = 0; i < images.length; i++) {
          let image = images[i]
          let parentNode = image.parentNode

          image.setAttribute('srcset',
            image.src + '?w=320 320w, ' +
            image.src + '?w=640 640w, ' +
            image.src + '?w=1280 1280w')
          image.setAttribute('originalsrc', image.src)

          if (!image.parentNode.parentNode.classList.contains('imageWrapper')) {
            wrap(image.parentNode, document.createElement('div'), 'imageWrapper')
          }

          wrap(image, document.createElement('div'), 'imageContent')

          if (image.getAttribute('alt')) {
            let imageCaption = document.createElement('p')
            imageCaption.classList.add('imageCaption')
            imageCaption.textContent = image.getAttribute('alt')

            image.parentNode.appendChild(imageCaption)
          }

          this.images.push(image.src)
        }


        // prepare links to open on new browser tab
        const links = div.querySelectorAll('a')

        for (let i = 0; i < links.length; i++) {
          links[i].setAttribute('target', '_blank')
        }




        return div.innerHTML
      } else {
        return htmlString
      }


    }

    const lead = prepareContent(post.leadNode.childMarkdownRemark.html)
    const content1 = prepareContent(post.content1Node.childMarkdownRemark.html)
    const content2 = prepareContent(post.content2Node.childMarkdownRemark.html)
    const content3 = prepareContent(post.content3Node.childMarkdownRemark.html)




    this.images = uniq(this.images)

    const gallery = (<div className={styles.gallery} ref={(gallery) => this.gallery = gallery}>
      {this.images.map((url, index) => {
        return (
          <a href={url}
            key={index}
            data-at-450={url + '?w=450'}
            data-at-800={url + '?w=800'}
            data-at-1366={url + '?w=1366'}
            data-at-1920={url + '?w=1920'}>
            <img src={url + '?w=100'}/>
          </a>
        )
      })}
    </div>)

    this.recommendedPosts = this.recommendedPosts || (() => {
      const allPosts = this.props.data.allDatoCmsCikkek.edges
      const selected = []
      const alreadyInSelected = []

      while (selected.length < 3 && allPosts.length > 3) {
        let pos = Math.floor((Math.random() * allPosts.length))

        if (allPosts[pos].node.id !== post.id && alreadyInSelected.indexOf(allPosts[pos].node.id) == -1) {
          alreadyInSelected.push(allPosts[pos].node.id)
          selected.push(allPosts[pos])
        }
      }

      return selected
    })()


    return (
      <Layout location={this.props.location}  readOnlyAPIToken={this.props.data.site.siteMetadata.readOnlyAPIToken}>

        <Helmet
          htmlAttributes={{ lang: 'hu' }}
          meta={[{ name: 'description', content: pageDescription }]}
          title={pageTitle}>
          {/*<script src="https://cdnjs.cloudflare.com/ajax/libs/tiny-slider/2.8.7/min/tiny-slider.js"></script>*/}
        </Helmet>

        <div className={styles.headerImage}>
          <picture>
            <source srcset={post.fejleckep && post.fejleckep.url + '?w=600'} media="(max-width: 600px)"/>
            <source srcset={post.fejleckep && post.fejleckep.url + '?w=1024'} media="(max-width: 1024px)"/>
            <source srcset={post.fejleckep && post.fejleckep.url + '?w=2300'} media="(max-width: 1920px)"/>
            <img src={post.fejleckep && post.fejleckep.url + '?w=1024&q=1'}/>
          </picture>
          <button onClick={this.saveFav} className={styles.favBtn}>
            {!this.state.favourite && <i alt="Kedvenc" data-icon="star-empty"></i>}
            {this.state.favourite && <i alt="Kedvenc törlése" data-icon="star"></i>}
          </button>
        </div>

        {gallery}


        <div className={styles.postContent} id="postContent">

          <div className={styles.subtitle}>{post.felcim}</div>

          <h1 className={styles.title}>{post.cim}</h1>

          <div className={styles.lead} dangerouslySetInnerHTML={{ __html: lead }} />

          <p className={styles.postData}>
            Szerző: <Link to={'/search?a=' + post.szerzo}>{post.szerzo}</Link>
            {post.kep &&
              <>
                , Kép: {post.kep}
              </>
            }
          </p>

          <div className={styles.postDateWrapper}>
            <p className={styles.postDate}>{_h.formatDate(post.kozzeteve)}</p>
          </div>

          <div className={styles.postBody} dangerouslySetInnerHTML={{ __html: content1 }} />

          {!this.state.content2Visible
            && <div className={styles.btnWrapper}><button onClick={this.setContent2Visible}>
                Tovább
                {/*Még {post.content2Node.childMarkdownRemark.timeToRead} perc*/}
            </button></div>
          }

          {this.state.content2Visible && <div className={styles.postBody} dangerouslySetInnerHTML={{ __html: content2 }} />}

          {this.state.content2Visible && !this.state.content3Visible
            && <div className={styles.btnWrapper}><button onClick={this.setContent3Visible}>
                Tovább
                {/*Még {post.content3Node.childMarkdownRemark.timeToRead} perc*/}
            </button></div>
          }
          {this.state.content3Visible && <div className={styles.postBody} dangerouslySetInnerHTML={{ __html: content3 }} />}
        </div>


        <h1 className={styles.recommendedTitle}>Cikkajánló</h1>
        <div className={styles.recommendedWrapper}>

          {this.recommendedPosts.map((post, index) => {
            return (
              <ArticleBox
                key={post.node.id}
                type="other"
                cim={post.node.cim}
                lead={post.node.leadNode.childMarkdownRemark.html}
                linkTo={post.node.slug}
                image={post.node.fejleckep}
                szerzo={post.node.szerzo} />
            )
          })}
        </div>

{/*        <ul>
          <li>{previous && <Link to={previous.slug} rel="prev">← {previous.cim}</Link>}</li>
          <li>{next && <Link to={next.slug} rel="next">{next.cim} →</Link>}</li>
        </ul>

                cim={post.node.cim}
                lead={post.node.leadNode.childMarkdownRemark.html}
                image={post.node.fejleckep}
                linkTo={post.node.slug}
              />



*/}
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        productionMode
        fullAPIToken
        readOnlyAPIToken
      }
    }

    datoCmsCikkek (slug: {eq: $slug}) {
      id
      cim
      kozzeteve
      szerzo
      kep
      felcim

      fejleckep {
        url
      }

      leadNode {
        childMarkdownRemark {
          html
        }
      }

      content1Node {
        childMarkdownRemark {
          html
          timeToRead
        }
      }

      content2Node {
        childMarkdownRemark {
          html
          timeToRead
        }
      }

      content3Node {
        childMarkdownRemark {
          html
          timeToRead
        }
      }
    }
    allDatoCmsCikkek {
      edges {
        node {
          id
          cim
          slug
          szerzo
          leadNode {
            childMarkdownRemark {
              html
            }
          }
          fejleckep {
            url
          }
        }
      }
    }
  }
`
